import {useRequest} from 'core/hooks';

const PRODUCTS_BASE_URL = '/products';

export const useCreateProductWithGPT = () =>
    useRequest<string, any>((recognizedText) => ({
        url: `${PRODUCTS_BASE_URL}/askAI`,
        method: 'GET',
        params: {
            text: recognizedText,
        }
    }), true);
