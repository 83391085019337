import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';

import {TProduct, TProductProperties, TScan} from 'core/types';
import {PRODUCT_FIELDS_CONFIG} from 'core/configs';
import {checkRequiredProductField} from 'core/utils';
import {ProductField} from 'components/common/productField';
import {Picture} from 'components/common/picture';
import {Button, ButtonThemes, ButtonTypes} from 'components/common/newButton';
import {useAppContext} from 'components/app/AppContext';
import {ReactComponent as WarningIcon} from 'assets/images/warning.svg';
import styles from './CreateWithScanModal.module.css';

export const Content = memo<IProps>(({
    scan,
    properties,
    filledProduct,
    onConfirm,
    onEdit,
    onCancel,
}) => {
    const [showError, setShowError] = useState<boolean>(false);
    const [activeInput, setActiveInput] = useState<string | null>();

    const {
        setIsFindModalOpen,
        setIsCreateWithScanModalOpen,
        enteredBrandInSearch,
        enteredProductInSearch,
    } = useAppContext();
    const {
        id,
        scanImages,
        productSearchLog,
    } = scan || {};
    const [editedProduct, setEditedProduct] = useState<TProduct>({});

    const scanImageUrl = useMemo(() => {
        if (scanImages?.[0]) {
            const {url} = scanImages?.[0];
            return url;
        }

        return null;
    }, [scanImages]);

    const handleControlClick = useCallback(() => {
        setIsFindModalOpen(true);
        setIsCreateWithScanModalOpen(false);
    }, []);

    const handleInputChange = useCallback((value: string | Array<string>, field: string) => {
        setEditedProduct({...editedProduct, [field]: value});
    }, [editedProduct, setEditedProduct]);

    const Control = useMemo(() => (
        <div className={styles.onPicture}>
            <Button text='Change' type={ButtonTypes.micro} onClick={handleControlClick} />
        </div>
    ), []);

    const getStrictValue = useCallback((key: keyof TProduct) => {
        if (key === 'brand') {
            return enteredBrandInSearch;
        }
        if (key === 'name') {
            return enteredProductInSearch;
        }
    }, [enteredBrandInSearch, enteredProductInSearch]);

    const FieldsNodes = useMemo(() => PRODUCT_FIELDS_CONFIG.map((field) => {
        return (
            <ProductField
                key={field.key}
                field={field}
                properties={properties}
                isActive={activeInput === field.label}
                showInvalid={showError}
                editedProduct={editedProduct}
                product={filledProduct}
                onClick={(value) => {
                    setShowError(false);
                    setActiveInput(value);
                }}
                onChange={(value) => {
                    handleInputChange(value, field.key);
                }}
                strictValue={getStrictValue(field.key)}
            />
        );
    }), [
        activeInput,
        properties,
        editedProduct,
        showError,
        setShowError,
        setActiveInput,
        handleInputChange,
        getStrictValue,
        filledProduct,
    ]);

    const needSave = useMemo(() => {
        return Object.values(editedProduct as object).some((elem) => Boolean(elem.length));
    }, [editedProduct]);

    const handleClick = useCallback(() => {
        if (!editedProduct || !Object.values(editedProduct).length) return;

        const areAllFields = PRODUCT_FIELDS_CONFIG.every(({key}) => checkRequiredProductField(editedProduct, key));

        if (!areAllFields) {
            setShowError(true);
            return;
        }

        onConfirm?.();
    }, [editedProduct, setShowError, onConfirm]);

    useEffect(() => {
        if (Boolean(editedProduct)) {
            onEdit?.(editedProduct!);
        }
    }, [editedProduct, onEdit]);

    useEffect(() => {
        if (!enteredBrandInSearch && !enteredProductInSearch) return;

        if (Boolean(enteredBrandInSearch) && !editedProduct.brand) {
            setEditedProduct({...editedProduct, brand: enteredBrandInSearch});
        }

        if (Boolean(enteredProductInSearch) && !editedProduct.name) {
            setEditedProduct({...editedProduct, name: enteredProductInSearch});
        }
    }, [enteredProductInSearch, enteredBrandInSearch, editedProduct]);

    useEffect(() => {
        if (!!filledProduct) {
            setEditedProduct({...filledProduct});
        }
    }, [filledProduct]);

    return (
        <>
            <div className={styles.left}>
                <Picture
                    url={scanImageUrl}
                    isMainPicture
                    hidePictureMenu
                />
                <div className={styles.pictureSign}>
                    Scan ID:
                    <span> {id}</span>
                </div>
                <div className={styles.blockTitle}>
                    Recognized info
                </div>
                <div className={styles.info}>
                    <div className={styles.infoCard}>
                        <div className={styles.infoCardTitle}>Other information</div>
                        <div>
                            {Boolean(productSearchLog)
                                ? (productSearchLog!.OCR.map(({word}, id) => (
                                        <div key={`ocr_${id}`}>{word}</div>
                                    ))
                                )
                                : ('No')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.right}>
                <Picture
                    url={scanImageUrl}
                    cornerControl={Control}
                    isMainPicture
                    hidePictureMenu
                />
                <div className={styles.pictureSign}>
                    Scan ID:
                    <span> {scan!.id}</span>
                </div>
                <div className={styles.blockTitle}>
                    Product Info
                </div>
                <div className={styles.info}>
                    {FieldsNodes}
                </div>
                {(!needSave || showError) && (
                    <div className={cn(styles.error, {[styles.errorHidden]: !showError})}>
                        <div>
                            <WarningIcon />
                        </div>
                        <div className={styles.errorText}>
                            Not enough information! Please complete all fields before saving the product.
                        </div>
                    </div>
                )}
                {needSave && (
                    <div className={styles.buttons}>
                        <Button
                            text='+ Add this product'
                            onClick={handleClick}
                        />
                        <Button
                            text='Cancel'
                            theme={ButtonThemes.secondary}
                            onClick={onCancel}
                        />
                    </div>
                )}
            </div>
        </>
    );
});

interface IProps {
    scan?: TScan | null;
    properties: TProductProperties | null;
    onConfirm?: () => void;
    onEdit?: (product: TProduct) => void;
    onCancel?: () => void;
    filledProduct: TProduct | null;
}
