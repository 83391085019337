import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';

import {useAppContext} from 'components/app/AppContext';
import {Modal} from 'components/common/modal';
import {ConfirmModal} from 'components/blocks/confirmModal';
import {CancelModal} from 'components/blocks/cancelModal';
import {TProduct} from 'core/types';
import {usePostProductImage, useProductsProperties, useSmartCreateProduct} from 'queries/products';
import {usePatchScan} from 'queries/scans';
import {useCreateProductWithGPT} from 'queries/gpt';
import {ReactComponent as GptIcon} from 'assets/images/ai.svg';
import {Content} from './Content';
import styles from './CreateWithScanModal.module.css';

export const CreateWithScanModal = memo(() => {
    const {setIsCreateWithScanModalOpen, scan, setIsSaveErrorModalOpen, setIsSuccessSaveModalOpen} = useAppContext();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
    const [editedProduct, setEditedProduct] = useState<TProduct>();

    const [createdData, fetchCreate] = useSmartCreateProduct();
    const [patchedScanImage, fetchPatchScan] = usePatchScan();
    const [createdProductImage, fetchCreateProductImage] = usePostProductImage();
    const [{data: properties}, fetchProperties] = useProductsProperties();
    const [createdDataWithGPT, fetchGPT] = useCreateProductWithGPT();

    const handleClose = useCallback(() => {
        setIsCreateWithScanModalOpen(false);
    }, []);
    const handleConfirm = useCallback(() => {
        setIsConfirmModalOpen(true);
    }, []);
    const handleConfirmOk = useCallback(() => {
        setIsConfirmModalOpen(false);

        if (!editedProduct) return;

        //@ts-ignore
        const spfValue = Array.isArray(editedProduct.spf) ? editedProduct.spf[0] : editedProduct.spf;
        const transformatedCopy = {
            ...editedProduct,
            spf: spfValue === 'No' ? 0 : Number(spfValue),
            //@ts-ignore
            ingredients: (typeof editedProduct.ingredients === 'string' ? editedProduct.ingredients?.split(/(?:\n|[.,]\s+)/) : editedProduct.ingredients) || [],
            //@ts-ignore
            keyIngredients: (typeof editedProduct.keyIngredients === 'string' ? editedProduct.keyIngredients?.split(/(?:\n|[.,]\s+)/) : editedProduct.keyIngredients) || [],
            warning: editedProduct.warning || '',
            citiesInfos: editedProduct.citiesInfos?.map((text) => ({city: text})) || [],
        };

        //@ts-ignore
        fetchCreate(undefined, transformatedCopy);
    }, [editedProduct]);
    const handleCancel = useCallback(() => {
        if (editedProduct && Object.values(editedProduct as object).some((elem) => Boolean(elem.length))) {
            setIsCancelModalOpen(true);
        } else {
            handleClose();
        }
    }, [editedProduct]);
    const handleCancelOk = useCallback(() => {
        setIsCancelModalOpen(false);
        handleClose();
    }, []);

    const ContentNode = useMemo(() => (
        <Content
            scan={scan}
            properties={properties}
            filledProduct={createdDataWithGPT.data}
            onConfirm={handleConfirm}
            onEdit={setEditedProduct}
            onCancel={handleCancel}
        />
    ), [scan, properties, handleConfirm, setEditedProduct, createdDataWithGPT.data]);

    useEffect(() => {
        // @ts-ignore
        if (!!createdData.data && !createdData.isPending && !createdData.error) {
            // @ts-ignore
            fetchPatchScan({scanId: scan.id, productId: createdData.data.productId});
        }
        // @ts-ignore
    }, [createdData.data, createdData.isPending, createdData.error, scan.id]);

    useEffect(() => {
        if (typeof patchedScanImage.data === 'string' && !patchedScanImage.isPending && !patchedScanImage.error) {
            // @ts-ignore
            if (!scan?.scanImages?.[0]?.url || !createdData.data?.productId) return;

            // @ts-ignore
            fetchCreateProductImage({id: createdData.data.productId, url: scan.scanImages[0].url});
        }
        // @ts-ignore
    }, [patchedScanImage.data, patchedScanImage.isPending, patchedScanImage.error, createdData.data?.productId, scan?.scanImages?.[0]?.url]);

    useEffect(() => {
        if (!!createdProductImage.data && !createdProductImage.isPending && !createdProductImage.error) {
            setIsSuccessSaveModalOpen(true);
            handleClose();
        }
    }, [createdProductImage.data, createdProductImage.isPending, createdProductImage.error]);

    useEffect(() => {
        fetchProperties();
    }, []);

    useEffect(() => {
        //@ts-ignore
        if (Boolean(createdData?.error)) {
            setIsSaveErrorModalOpen(true);
        }
        //@ts-ignore
    }, [createdData?.error]);

    useEffect(() => {
        if (!!createdDataWithGPT?.data) {
            setEditedProduct(createdDataWithGPT.data);
        }
    }, [createdDataWithGPT]);

    const extraControls = useMemo(() => {
        if (!scan?.productSearchLog?.OCR) {
            return null;
        }

        const dataForGTP = scan.productSearchLog.OCR.map(({word}) => word).join(' ');

        if (!dataForGTP) {
            return null;
        }

        const handleGPTClick = () => {
            fetchGPT(encodeURI(dataForGTP));
        };

        return [
            <div
                key={'modal_gpt_icon'}
                className={cn(styles.action, {[styles.loader]: true})}
                onClick={handleGPTClick}
            >
                <GptIcon />
            </div>,
        ];
    }, [scan?.productSearchLog?.OCR, fetchGPT, createdDataWithGPT.isPending]);

    return (
        <>
            <Modal
                onClose={handleCancel}
                title='Create a new product'
                borderUnderTitle
                extraHeaderControls={extraControls}
            >
                {ContentNode}
            </Modal>
            {isConfirmModalOpen && (
                <ConfirmModal
                    onOk={handleConfirmOk}
                    onCancel={() => setIsConfirmModalOpen(false)}
                />
            )}
            {isCancelModalOpen && (
                <CancelModal
                    onOk={handleCancelOk}
                    onCancel={() => setIsCancelModalOpen(false)}
                />
            )}
        </>
    );
});
