import {memo, useCallback, useEffect, useMemo} from 'react';

import {TScan} from 'core/types';
import {PRODUCT_FIELDS_CONFIG} from 'core/configs';
import {Picture} from 'components/common/picture';
import {ProductField} from 'components/common/productField';
import {Button, ButtonThemes} from 'components/common/newButton';
import {useAppContext} from 'components/app/AppContext';
import {useMakeUnError, useMoveScanFromInProgressToError, usePatchScan} from 'queries/scans';
import styles from './ErrorModal.module.css';
import {useLocation} from 'react-router-dom';
import {ROUTES} from '../../../core/constants';
import {useProduct} from '../../../queries/products';

export const Content = memo<IProps>(({
    scan,
}) => {
    const [noErrorScan, fetchNoErrorScan] = useMakeUnError();
    const [{data, error}, moveScanToError] = useMoveScanFromInProgressToError();
    const [patchedScanImage, fetchPatchScan] = usePatchScan();
    const [{data: newProduct, isPending: newProductPending}, fetchOneProduct] = useProduct();
    const {setRefresh, setIsErrorModalOpen, setIsFindModalOpen, setProduct, setIsProductModalOpen} = useAppContext();
    const {pathname} = useLocation();

    const {
        id,
        scanImages,
        product,
        productId,
        productSearchLog,
    } = scan || {};

    const scanImageUrl = useMemo(() => {
        if (scanImages?.[0]) {
            const {url} = scanImages?.[0];
            return url;
        }

        return null;
    }, [scanImages]);

    const productImageUrl = useMemo(() => {
        if (product?.productImages?.[0]) {
            return product.productImages[0].url;
        }

        return null;
    }, [product]);

    const FieldsNodes = useMemo(() => PRODUCT_FIELDS_CONFIG.map((field) => {
        return (
            <ProductField
                readMode
                key={field.key}
                field={field}
                product={product}
            />
        );
    }), [product]);

    const handleYesClick = useCallback(() => {
        if (!scan?.id) {
            return;
        }

        const isProductUncompleted = scan.inProgress && scan.productId !== 0;

        if (isProductUncompleted && !!product && pathname === ROUTES.IN_PROGRESS) {
            // @ts-ignore
            fetchPatchScan({scanId: scan.id, productId: product.id});
        } else {
            fetchNoErrorScan(scan!.id);
        }
    }, [scan?.id, fetchNoErrorScan, pathname]);

    const handleNoClick = useCallback(() => {
        const isProductUncompleted = scan?.inProgress && scan.productId !== 0;

        if (isProductUncompleted && !!product && pathname === ROUTES.IN_PROGRESS) {
            moveScanToError(scan.id);
            return;
        }

        setIsFindModalOpen(true);
        setIsErrorModalOpen(false);
    }, [setIsFindModalOpen, setIsErrorModalOpen]);

    useEffect(() => {
        if (typeof noErrorScan.data !== 'string' || Boolean(noErrorScan.error)) return;

        setRefresh(true);
        setIsErrorModalOpen(false);
    }, [noErrorScan.data, noErrorScan.error]);

    useEffect(() => {
        if (data === '' && !error) {
            setRefresh(true);
            setIsErrorModalOpen(false);
        }
    }, [data, error]);

    useEffect(() => {
        if (typeof patchedScanImage.data === 'string' && !patchedScanImage.isPending && !patchedScanImage.error) {
            if (!product) {
                return;
            }

            // @ts-ignore
            fetchOneProduct(product.id);
        }
        // @ts-ignore
    }, [patchedScanImage.data, patchedScanImage.isPending, patchedScanImage.error, product]);

    useEffect(() => {
        if (!!newProduct) {
            setProduct(newProduct);
            setIsProductModalOpen(true);
            setIsErrorModalOpen(false);
        }
    }, [newProduct]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.pictures}>
                <div className={styles.left}>
                    <Picture url={scanImageUrl} isMainPicture hidePictureMenu />
                    <div className={styles.pictureSign}>
                        Scan ID:
                        <span> {id}</span>
                    </div>
                </div>
                <div className={styles.right}>
                    <Picture
                        url={productImageUrl}
                        isMainPicture
                        hidePictureMenu
                    />
                    <div className={styles.pictureSign}>
                        Product ID:
                        <span> {product?.id || productId || 'No'}</span>
                    </div>
                </div>
            </div>
            <div className={styles.buttons}>
                <Button
                    text='No'
                    theme={ButtonThemes.secondary}
                    onClick={handleNoClick}
                />
                <Button
                    text='Yes'
                    onClick={handleYesClick}
                    isLoading={noErrorScan.isPending || patchedScanImage.isPending || newProductPending}
                />
            </div>
            <div className={styles.textWrapper}>
                <div className={styles.left}>
                    <div className={styles.blockTitle}>
                        Recognized info
                    </div>
                    <div className={styles.info}>
                        <div className={styles.infoCard}>
                            <div className={styles.infoCardTitle}>Other information</div>
                            <div>
                                {Boolean(productSearchLog)
                                    ? (productSearchLog!.OCR.map(({word}, id) => (
                                            <div key={`ocr_${id}`}>{word}</div>
                                        ))
                                    )
                                    : ('No')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.blockTitle}>
                        Product Info
                    </div>
                    <div className={styles.info}>
                        {FieldsNodes}
                    </div>
                </div>
            </div>
        </div>
    );
});

interface IProps {
    scan?: TScan | null;
}
